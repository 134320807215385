import { Form, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Modal, { HeaderStyled } from '../modal';

import { ButtonCustom, ButtonThirdParty } from '../button-component';
import { useWeb3Auth } from '../../contexts/web3auth/web3auth';
import { BUTTON_CLOSE_ICON, COLOR_GOOGLE_ICON, DM2C_TEXT_ICON, EMAIL_BLACK, EMAIL_GRAY } from '../../theme/images';
import { BoldTextWhiteWithShadow } from '../text';
import { LOGIN_TYPE } from '../../constants/common';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputType from '../inputType';
import { getLocalStorage, STORAGE } from '../../utils/storage';

const ContentStyled = styled.div`
  padding: 12px 24px 24px;
  position: relative;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 12px 0 24px;
  }
  .label-email {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }

  .form-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* gap: 16px !important; */
    margin-top: -12px;
    > button,
    > div {
      margin-bottom: 16px;
    }

    .text-or {
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      @supports (-webkit-text-stroke: 1.5px #000000) {
        color: white;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #000000;
        paint-order: stroke fill;
      }
    }
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .input-email {
    margin-bottom: 0px;
    border: 2px solid #0099fe;
    box-shadow: none;
    .ant-input {
      color: #000000;
      font-weight: 700;
      font-size: 14px;
      &::placeholder {
        color: #cecfd3;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .content {
    display: grid;
    grid-column: 1;
    justify-items: center;

    .linear_text {
      font-size: 16px;
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif;
      background: linear-gradient(90deg, rgba(236, 190, 225, 1) 0%, rgba(163, 238, 248, 1) 100%);
      background-clip: inherit;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
    .description {
      color: white;
      font-weight: 400;
      font-size: 12px;
    }
  }
`;

const LoginDM2Modal = ({ isOpen, onClose = () => {}, setModalToast }) => {
  const { t } = useTranslation('auth');
  const [loading, setLoading] = useState(false);

  const { loginAuth0EmailPasswordLess, loginGoogle } = useWeb3Auth();

  const formik = useFormik({
    initialValues: { email: getLocalStorage(STORAGE.EMAIL_DM2) ? getLocalStorage(STORAGE.EMAIL_DM2) : '' },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        // eslint-disable-next-line
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, t('validation_messages:EMAIL_INVALID'))
        .email(t('validation_messages:EMAIL_INVALID'))
        .max(40, t('validation_messages:MAX_LENGTH_30'))
        .required(t('validation_messages:EMAIL_REQUIRED')),
    }),
    enableReinitialize: true,
    onSubmit: ({ email }) => {
      handleLogin(email);
    },
  });

  const { touched, handleSubmit, values, errors, setFieldValue, setFieldTouched } = formik;

  const handleCloseModal = () => {
    if (onClose) onClose();
  };

  const handleLogin = async (value) => {
    try {
      setLoading(true);
      await loginAuth0EmailPasswordLess(value, LOGIN_TYPE.DM2);
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoginGoogle = async () => {
    try {
      setLoading(true);
      await loginGoogle(LOGIN_TYPE.DM2);
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          if (!loading) handleCloseModal();
        }}
        destroyOnClose
        className="custom-modal"
        padding={0}
        title={null}
        width={500}
        color="white"
        closeIcon={<img className="icon-close" src={BUTTON_CLOSE_ICON} alt="icon" />}
      >
        <Spin spinning={loading}>
          <HeaderStyled color="white">
            <BoldTextWhiteWithShadow className="title-header" data-text={t('login.login_with_dm2c')}>
              {t('login.login_with_dm2c')}
            </BoldTextWhiteWithShadow>
          </HeaderStyled>
          <ContentStyled>
            <FormContent>
              <div className="content">
                <img src={DM2C_TEXT_ICON} alt="icon" width={135} height={32} />
              </div>
              <Form.Item
                validateStatus={errors?.email && touched?.email ? 'error' : ''}
                help={
                  <div dangerouslySetInnerHTML={{ __html: touched?.email && errors?.email ? errors?.email : '' }} />
                }
              >
                <InputType
                  className="input-email"
                  prefix={
                    <img src={values?.email ? EMAIL_BLACK : EMAIL_GRAY} className="site-form-item-icon" alt="icon" />
                  }
                  placeholder={t('login.email')}
                  name="email"
                  onChange={text => {
                    setFieldValue && setFieldValue('email', text.target.value || '');
                  }}
                  value={values?.email}
                  onBlur={() => setFieldTouched('email', true)}
                  disabled={loading}
                />
              </Form.Item>
            </FormContent>
            <div className="form-btn">
              <ButtonCustom type="submit" color="pink" disabled={loading} onClick={handleSubmit}>
                {t('login.login_with_dm2c')}
              </ButtonCustom>
              <div className="text-or">{t('login.or')}</div>
              <ButtonThirdParty logo={COLOR_GOOGLE_ICON} disabled={loading} onClick={handleLoginGoogle}>
                {t('login.sign_in_goole')}
              </ButtonThirdParty>
            </div>
          </ContentStyled>
        </Spin>
      </Modal>
    </>
  );
};

export default LoginDM2Modal;
